import api from '@/services/api/api';

const defaultOptions = {
  staleTime: Infinity,
  refetchOnFocus: false,
  refetchOnReconnect: false,
};

export const usePinnedElements = (
  options = {
    staleTime: Infinity,
    refetchOnFocus: false,
    refetchOnReconnect: false,
  },
) => {
  const pinned = useQuery(
    ['pinned'],
    async () => {
      const { data } = await api.pinned.getPinnedElements();
      return data;
    },
    { ...defaultOptions, ...options, select: ({ data }) => data },
  );

  onServerPrefetch(async () => {
    await pinned.suspense();
  });

  return {
    pinned,
  };
};
