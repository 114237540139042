import api from '@/services/api/api';

const defaultOptions = {
  staleTime: Infinity,
  refetchOnFocus: false,
  refetchOnReconnect: false,
};

export const useThisDayInHistory = (options = {}) => {
  const thisDayInHistory = useQuery(
    ['thisDayInHistory'],
    async () => {
      const { data } = await api.others.getDayInHistory();
      return data;
    },
    { ...defaultOptions, ...options, select: (data) => data },
  );

  onServerPrefetch(async () => {
    await thisDayInHistory.suspense();
  });

  return {
    thisDayInHistory,
  };
};
