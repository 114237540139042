<template>
  <MainPageBlockWrapperNew
    title="Города трудовой доблести"
    href="/presentation"
  >
    <div>
      <ClientOnly>
        <Swiper
          :slides-per-view="1"
          :space-between="20"
          :breakpoints="breakpoints"
          @swiper="onSwiper"
        >
          <SwiperSlide v-for="city in presentation" :key="city.id">
            <MainPageCardsReworkedPresentationCard
              :data="city"
              class="cursor-pointer"
              @click="showPresentation(city)"
            />
          </SwiperSlide>
        </Swiper>
      </ClientOnly>
    </div>

    <SlidersSliderControl
      type="prev"
      class="absolute left-[-16px] top-[55%] z-10 hidden md:block"
      :class="isBeginning ? 'opacity-50' : 'cursor-pointer'"
      @click="slidePrev()"
    />
    <SlidersSliderControl
      type="next"
      class="absolute right-[-16px] top-[55%] z-10 hidden md:block"
      :class="isEnd ? 'opacity-50' : 'cursor-pointer'"
      @click="slideNext()"
    />
  </MainPageBlockWrapperNew>
</template>

<script setup>
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/vue';

import presentation from '~/assets/data/presentation';

const { onSwiper, slideNext, slidePrev, isEnd, isBeginning } = useSwiper();

const breakpoints = {
  1024: {
    slidesPerView: 2,
  },
  1280: {
    slidesPerView: 3,
  },
};

const modalsStore = useModalsStore();
const { isHorizontalPadOrMore } = useBreakpoint();

const getSlides = (city) => {
  const slides = usePresentation(city);
  return slides;
};

const showPresentation = (city) => {
  isHorizontalPadOrMore.value && document.documentElement.requestFullscreen();
  modalsStore.setPropertiesModal({
    slides: getSlides(city),
    fullscreen: isHorizontalPadOrMore.value,
  });
  modalsStore.showGalleryPopup();
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  @apply hidden lg:block;
}
</style>
