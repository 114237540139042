export const staticCollection = [
  {
    model_type: 'highlight',
    id: 718,
    title: 'Парад Победы',
    preamble: null,
    slug: '9-maya-den-pobedy',
    announce:
      'Подборка материалов ко Дню Великой Победы от редакции портала История.рф',
    show_in_main: false,
    published_at: '2024-05-08T12:24:52.000000Z',
    count: 45,
    likes: 1,
    views: 2597,
    has_like: false,
    has_bookmark: false,
    image: {
      model_type: 'image',
      id: 31004,
      alt: null,
      src: '/images/highlight/32/HRWnRvEFv0RbzTowIiavDhjb7vVAw3UrWUEYeP8O.jpg',
      preview:
        '/images/highlight/32/HRWnRvEFv0RbzTowIiavDhjb7vVAw3UrWUEYeP8O_min.jpg',
      original: null,
      order: 1,
      desktop:
        '/images/highlight/32/f8VWTKMtjMV9uSXsNYgedJpT91sbvVQigfQYC1yZ1715175827.jpg',
      mobile:
        '/images/highlight/32/ePyVz6GFWS2UCW7J2YILUzTvJA8hghErZAkrjf3b1715175827.jpg',
      app: '/images/highlight/32/O7uqZMEuFznFEh7EuR1O6M5udG1CmGjlQLDWewUt1715175827.jpg',
    },
    list: [
      {
        model_type: 'article',
        id: 4586,
        title: 'Главный праздник в году: как отмечали первый День Победы',
        preamble: null,
        slug: 'glavnyy-prazdnik-v-godu-kak-otmechali-pervyy-den-pobedy',
        image: {
          model_type: 'image',
          id: 7208,
          alt: null,
          src: '/images/articles/08/DnkxNkpJQJK3jb0Gxg7lNyBPMBCR50cNGUDJcZyx.jpg',
          preview:
            '/images/articles/08/DnkxNkpJQJK3jb0Gxg7lNyBPMBCR50cNGUDJcZyx_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2021-05-10T06:07:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 681,
            slug: 'barinova-oksana',
            firstname: 'Оксана',
            surname: 'Баринова',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: false,
          },
        ],
        likes: 4,
        views: 8075,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 2572,
        title: 'Краткий курс истории. «Бессмертный полк»',
        preamble: null,
        slug: 'kratkii-kurs-istorii-biessmiertnyi-polk',
        image: {
          model_type: 'image',
          id: 3680,
          alt: null,
          src: '/images/articles/04/5C4w3dKftgeYcV0W6VlHdWEAWevkEdSFvgBCOGDP.jpg',
          preview:
            '/images/articles/04/5C4w3dKftgeYcV0W6VlHdWEAWevkEdSFvgBCOGDP_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2018-05-09T00:10:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 504,
            slug: 'sukharnikov-ievghienii-sierghieievich',
            firstname: 'Евгений',
            surname: 'Сухарников',
            patronymic: '',
            image: {
              model_type: 'image',
              id: 11307,
              alt: null,
              src: '/images/author/12/EHSgrEtxioK4IEMoNidkiBEY2mcdhRjZFTnfXW6l.jpg',
              preview:
                '/images/author/12/EHSgrEtxioK4IEMoNidkiBEY2mcdhRjZFTnfXW6l_min.jpg',
              original: null,
              order: 1,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: false,
          },
        ],
        likes: 6,
        views: 11608,
        comments: 1,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 25,
        title: 'Слава нашему великому народу, народу-победителю!',
        preamble: null,
        slug: 'slava-nashiemu-vielikomu-narodu-narodu-pobieditieliu',
        image: {
          model_type: 'image',
          id: 19704,
          alt: null,
          src: '/images/articles/20/1cXE9bRkdw1yvbzeFIt1mKJeB5Bj72A3gFc6a9A8.jpg',
          preview:
            '/images/articles/20/1cXE9bRkdw1yvbzeFIt1mKJeB5Bj72A3gFc6a9A8_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '1945-05-09T00:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 51,
            slug: 'stalin-iosif-vissarionovich',
            firstname: 'Иосиф',
            surname: 'Сталин',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: '&nbsp;',
            is_subscribed: false,
          },
        ],
        likes: 3,
        views: 30764,
        comments: 1,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 4354,
        title: 'День Победы 9 мая',
        preamble: null,
        slug: 'dien-pobiedy-9-maia-event',
        image: {
          model_type: 'image',
          id: 20799,
          alt: null,
          src: '/images/articles/21/tMEXqXEtK6qlm7r3c2FyEiPtk1uGdM36Y1BVr4aE.jpg',
          preview:
            '/images/articles/21/tMEXqXEtK6qlm7r3c2FyEiPtk1uGdM36Y1BVr4aE_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2013-06-25T13:27:45.000000Z',
        authors: [],
        likes: 8,
        views: 42075,
        comments: 2,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 4585,
        title: 'День Победы: от нас далек, но нам близок',
        preamble: null,
        slug: 'den-pobedy-ot-nas-dalek-no-nam-blizok',
        image: {
          model_type: 'image',
          id: 7207,
          alt: null,
          src: '/images/articles/08/nlLvUxsK60LcMDhKJ9zpcpiWvdFeBNpIBIGVJi7Y.jpg',
          preview:
            '/images/articles/08/nlLvUxsK60LcMDhKJ9zpcpiWvdFeBNpIBIGVJi7Y_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2021-05-09T06:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 717,
            slug: 'pozhidaiev-ilia',
            firstname: 'Илья',
            surname: 'Пожидаев',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: false,
          },
        ],
        likes: 0,
        views: 5539,
        comments: 0,
        number: null,
        comment: null,
      },
    ],
    number: null,
    comment: null,
    seo: {
      model_type: 'metadata',
      metatitle: '9 Мая - День Победы!',
      metadescription:
        'Подборка материалов ко Дню Великой Победы от редакции портала История.рф',
      index: true,
      follow: true,
      archive: true,
      last_modified: '2024-11-27T11:10:33.000000Z',
    },
    content_types: [
      {
        model: 'article',
        value: ['article'],
        name: 'Статьи',
        count: 26,
      },
      {
        model: 'audiomaterial',
        value: ['audiolecture'],
        name: 'Аудио',
        count: 4,
      },
      {
        model: 'videomaterial',
        value: ['videolecture', 'film'],
        name: 'Видео',
        count: 15,
      },
      {
        model: 'test',
        value: ['test'],
        name: 'Тесты',
        count: 0,
      },
      {
        model: 'biography',
        value: ['biography'],
        name: 'Биографии',
        count: 0,
      },
      {
        model: 'wiki',
        value: ['wiki'],
        name: 'Энциклопедии',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'region_heraldic',
        value: ['region_heraldic'],
        name: 'Регионы | Символика',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_route',
        value: ['region_route'],
        name: 'Регионы | Маршруты',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_article',
        value: ['region_article'],
        name: 'Регионы | Статьи',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'illustration',
        value: ['illustration'],
        name: 'Карты и иллюстрации',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document_collection',
        value: ['document_collection'],
        name: 'Документы',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document',
        value: ['document'],
        name: 'Документы',
        count: 0,
      },
    ],
  },
  {
    model_type: 'highlight',
    id: 161,
    title: 'Крылья Победы',
    preamble: null,
    slug: 'krylya-pobedy-samolety-velikoy-otechestvennoy-voyny',
    announce:
      'Роль авиации в любом военном противостоянии сложно переоценить. Перед началом Второй мировой войны руководство стран Оси и будущих государств Антигитлеровской коалиции не предполагало, что одной из главных составляющих грядущей войны станет противостояние в небе. В этой подборке мы собрали статьи о некоторых знаменитых советских самолетах времен Великой Отечественной войны.',
    show_in_main: false,
    published_at: '2023-11-24T09:51:34.000000Z',
    count: 22,
    likes: 3,
    views: 3979,
    has_like: false,
    has_bookmark: false,
    image: {
      model_type: 'image',
      id: 27161,
      alt: null,
      src: '/images/highlight/28/cjBvuL9yCfBEryVTA0XCBz8l7lA3Xtd55YsHOE7W.jpg',
      preview:
        '/images/highlight/28/cjBvuL9yCfBEryVTA0XCBz8l7lA3Xtd55YsHOE7W_min.jpg',
      original: null,
      order: 1,
      desktop:
        '/images/highlight/28/Vb2PAuWrCURkXdUDjm1jdnAVf3a8XpNTfVYJAuwj1700819491.jpg',
      mobile:
        '/images/highlight/28/qJi0iNGrUHGQWkcon7mIjEcK9w0G3osHMSC5stnn1700819491.jpg',
      app: '/images/highlight/28/6IAfmsEWn4BEkS8RlwjuVaAaND6Vp31612Kdu3Gx1700819492.jpg',
    },
    list: [
      {
        model_type: 'article',
        id: 3441,
        title: '«Летающий танк»: Ил-2 штурмовик',
        preamble: null,
        slug: 'lietaiushchii-tank-il-2-shturmovik',
        image: {
          model_type: 'image',
          id: 747,
          alt: null,
          src: '/images/articles/01/NXYpQfe3dHlVaVpzXAXzwWC71MIN1LdkmOqdAxd8.jpg',
          preview:
            '/images/articles/01/NXYpQfe3dHlVaVpzXAXzwWC71MIN1LdkmOqdAxd8_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2019-10-21T11:30:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 624,
            slug: 'buranov-nikita',
            firstname: 'Никита',
            surname: 'Буранов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: 11070,
              alt: null,
              src: '/images/author/12/EKuq66iGh0i93TE0wHfhXd8mgMPFwepnQ56am7QT.jpg',
              preview:
                '/images/author/12/EKuq66iGh0i93TE0wHfhXd8mgMPFwepnQ56am7QT_min.jpg',
              original: null,
              order: 1,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 18,
        views: 38516,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 4854,
        title: 'Су-2. Самолет по фамилии «Иванов»',
        preamble: null,
        slug: 'su-2-samolet-po-familii-ivanov',
        image: {
          model_type: 'image',
          id: 11658,
          alt: null,
          src: '/images/articles/12/Dvkei2ci8rFI1bGRAWLZeXyqbRmWPNMjebrYP7vf.jpg',
          preview:
            '/images/articles/12/Dvkei2ci8rFI1bGRAWLZeXyqbRmWPNMjebrYP7vf_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2021-10-16T03:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 657,
            slug: 'trofimov-anton',
            firstname: 'Антон',
            surname: 'Трофимов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 3,
        views: 34121,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 4978,
        title: 'Истребитель МиГ-15. Самолет-солдат',
        preamble: null,
        slug: 'istrebitel-mig-15-samolet-soldat',
        image: {
          model_type: 'image',
          id: 13689,
          alt: null,
          src: '/images/articles/14/GXQcUHNVe7KKYSSMiufkfS7u1jjdxcQtSApDV5mt.jpg',
          preview:
            '/images/articles/14/GXQcUHNVe7KKYSSMiufkfS7u1jjdxcQtSApDV5mt_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2021-12-23T04:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 657,
            slug: 'trofimov-anton',
            firstname: 'Антон',
            surname: 'Трофимов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 12,
        views: 31236,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 5020,
        title: 'Бомбардировщик ТБ-3. Преемник «Ильи Муромца»',
        preamble: null,
        slug: 'bombardirovshchik-tb-3-preemnik-ili-muromca',
        image: {
          model_type: 'image',
          id: 14184,
          alt: null,
          src: '/images/articles/15/5AFKIZNumefTNncb3yxTJFiogfLxepttrE7tON3X.jpg',
          preview:
            '/images/articles/15/5AFKIZNumefTNncb3yxTJFiogfLxepttrE7tON3X_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2022-06-06T08:32:36.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 657,
            slug: 'trofimov-anton',
            firstname: 'Антон',
            surname: 'Трофимов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 4,
        views: 32323,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 4880,
        title: 'Истребитель Ла-5. Он прогнал «Фокке-Вульфы» с фронта',
        preamble: null,
        slug: 'istrebitel-la-5-on-prognal-fokke-vulfy-s-fronta',
        image: {
          model_type: 'image',
          id: 12073,
          alt: null,
          src: '/images/articles/13/ERR0bZWIRD26cNNNW83xpzh5xS7ISWdt3YNSDrac.jpg',
          preview:
            '/images/articles/13/ERR0bZWIRD26cNNNW83xpzh5xS7ISWdt3YNSDrac_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2021-10-31T04:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 657,
            slug: 'trofimov-anton',
            firstname: 'Антон',
            surname: 'Трофимов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 10,
        views: 34083,
        comments: 0,
        number: null,
        comment: null,
      },
    ],
    number: null,
    comment: null,
    seo: {
      model_type: 'metadata',
      metatitle: 'Крылья Победы',
      metadescription:
        'Роль авиации в любом военном противостоянии сложно переоценить. Перед началом Второй мировой войны руководство стран Оси и будущих государств Антигитлеровской коалиции не предполагало, что одной из главных составляющих грядущей войны станет противостояние в небе. В этой подборке мы собрали статьи о некоторых знаменитых советских самолетах времен Великой Отечественной войны.',
      index: true,
      follow: true,
      archive: true,
      last_modified: '2024-12-04T08:05:50.000000Z',
    },
    content_types: [
      {
        model: 'article',
        value: ['article'],
        name: 'Статьи',
        count: 11,
      },
      {
        model: 'audiomaterial',
        value: ['audiolecture'],
        name: 'Аудио',
        count: 0,
      },
      {
        model: 'videomaterial',
        value: ['videolecture', 'film'],
        name: 'Видео',
        count: 4,
      },
      {
        model: 'test',
        value: ['test'],
        name: 'Тесты',
        count: 0,
      },
      {
        model: 'biography',
        value: ['biography'],
        name: 'Биографии',
        count: 0,
      },
      {
        model: 'wiki',
        value: ['wiki'],
        name: 'Энциклопедии',
        count: 3,
        is_teacher: true,
      },
      {
        model: 'region_heraldic',
        value: ['region_heraldic'],
        name: 'Регионы | Символика',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_route',
        value: ['region_route'],
        name: 'Регионы | Маршруты',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_article',
        value: ['region_article'],
        name: 'Регионы | Статьи',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'illustration',
        value: ['illustration'],
        name: 'Карты и иллюстрации',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document_collection',
        value: ['document_collection'],
        name: 'Документы',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document',
        value: ['document'],
        name: 'Документы',
        count: 4,
      },
    ],
  },
  {
    model_type: 'highlight',
    id: 904,
    title: 'Политические итоги Великой Отечественной войны',
    preamble: null,
    slug: 'politicheskie-itogi-velikoy-otechestvennoy-voyny',
    announce:
      'Ближайшим и наиболее очевидным итогом Второй мировой войны были гигантские разрушения и людские потери. Война опустошила целые страны, обратила в развалины города и села, привела к гибели многих миллионов людей. \nОднако итоги Второй мировой войны не сводятся только к потерям и разрушениям. В результате войны изменился облик мира. \nГлавный политический итог Второй мировой войны заключается в победе над фашистскими агрессорами. Страны и народы, которым угрожал фашизм, отстояли свою независимость и свободу. Агрессивные государства: Германия, Италия, Япония и их союзники — были разгромлены. Их вооруженные силы, экономика, политика, идеология потерпели полный крах; их главарей отдали под суд, и они понесли заслуженное наказание.\nПолностью дискредитировала себя идеология фашизма, нацизма, расизма, колониализма.',
    show_in_main: false,
    published_at: '2024-12-03T12:19:29.000000Z',
    count: 13,
    likes: 0,
    views: 15,
    has_like: false,
    has_bookmark: false,
    image: {
      model_type: 'image',
      id: 43182,
      alt: null,
      src: '/images/highlight/44/lex3OwbLPJhJ8AADTsZ2jeHwWJZNT3BgavJb6e5k.jpg',
      preview:
        '/images/highlight/44/lex3OwbLPJhJ8AADTsZ2jeHwWJZNT3BgavJb6e5k_min.jpg',
      original: null,
      order: 1,
      desktop:
        '/images/highlight/44/0lDYk0ca58LIv0V6iBNq0cYnmrI0GuBg0f3gZQEY1733229433.jpg',
      mobile:
        '/images/highlight/44/omLzVSCRpMKnf97MhaTTQXqTuTWU0ks5wPMnsnvG1733229433.jpg',
      app: '/images/highlight/44/mdVHCxmGuF9o6hX11BmUUTJb6InP4xN63Yczb06Q1733229433.jpg',
    },
    list: [
      {
        model_type: 'article',
        id: 2923,
        title: 'С войной покончили мы счеты',
        preamble: null,
        slug: 's-voinoi-pokonchili-my-schiety',
        image: {
          model_type: 'image',
          id: 2250,
          alt: null,
          src: '/images/articles/03/pP7jIoiSEr92MuAY44kZMAKcP0aCjEZaCcGBTcS2.jpg',
          preview:
            '/images/articles/03/pP7jIoiSEr92MuAY44kZMAKcP0aCjEZaCcGBTcS2_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2019-01-29T12:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 630,
            slug: 'miedinskii-vladimir-rostislavovich',
            firstname: 'Владимир',
            surname: 'Мединский',
            patronymic: '',
            image: {
              model_type: 'image',
              id: 11078,
              alt: null,
              src: '/images/author/12/tEwdE8PBNbpwHHGW5JXwqhDjb9Z082X6UJEnZdG0.jpg',
              preview:
                '/images/author/12/tEwdE8PBNbpwHHGW5JXwqhDjb9Z082X6UJEnZdG0_min.jpg',
              original: null,
              order: 1,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 0,
        views: 8235,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 627,
        title: 'Пятнадцать уроков Второй мировой',
        preamble: null,
        slug: 'piatnadtsat-urokov-vtoroi-mirovoi',
        image: {
          model_type: 'image',
          id: 1171,
          alt: null,
          src: '/images/articles/02/cvpuNFMTL9ps7PyijxGGumN9jJLrwowgoiuvOH35.jpg',
          preview:
            '/images/articles/02/cvpuNFMTL9ps7PyijxGGumN9jJLrwowgoiuvOH35_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2015-08-30T00:00:00.000000Z',
        authors: [],
        likes: 8,
        views: 95347,
        comments: 1,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 3957,
        title: 'Потсдамская конференция',
        preamble: null,
        slug: 'potsdamskaia-konfierientsiia-event',
        image: {
          model_type: 'image',
          id: 20024,
          alt: null,
          src: '/images/articles/21/9TBeagiVT7kTt2laHzlxzN0V6L6UVa43Ljzewue7.jpg',
          preview:
            '/images/articles/21/9TBeagiVT7kTt2laHzlxzN0V6L6UVa43Ljzewue7_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2013-06-25T13:27:44.000000Z',
        authors: [],
        likes: 19,
        views: 53642,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 231,
        title:
          'Красная Армия в Европе в 1945 году в контексте информационной войны',
        preamble: null,
        slug: 'krasnaia-armiia-v-ievropie-v-1945-ghodu-v-kontiekstie-informatsionnoi-voiny',
        image: {
          model_type: 'image',
          id: 19727,
          alt: null,
          src: '/images/articles/20/Rlg0dHPEQeeyV33QDjk026SgKjH5n9cwCDiaBUEb.jpg',
          preview:
            '/images/articles/20/Rlg0dHPEQeeyV33QDjk026SgKjH5n9cwCDiaBUEb_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2013-04-18T00:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 129,
            slug: 'sieniavskaia-ieliena-spartakovna',
            firstname: 'Елена',
            surname: 'Сенявская',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 1,
        views: 47868,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 3228,
        title:
          'Продолжаем разговор о войне: научные факты о завершении Великой Отечественной',
        preamble: null,
        slug: 'prodolzhaiem-razghovor-o-voinie-nauchnyie-fakty-o-zaviershienii-vielikoi-otiechiestviennoi',
        image: {
          model_type: 'image',
          id: 4211,
          alt: null,
          src: '/images/articles/05/B0g34y4z4ZVKzf2fYI4tddKVfiFy2QIFlFMdN47B.jpg',
          preview:
            '/images/articles/05/B0g34y4z4ZVKzf2fYI4tddKVfiFy2QIFlFMdN47B_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2019-07-06T08:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 489,
            slug: 'isaiev-alieksiei-valierievich',
            firstname: 'Алексей',
            surname: 'Исаев',
            patronymic: '',
            image: {
              model_type: 'image',
              id: 12827,
              alt: null,
              src: '/images/author/13/KHJxMwJZK0qXvSQPNSYT1mQIjDt4VXhpuusK9q5N.jpg',
              preview:
                '/images/author/13/KHJxMwJZK0qXvSQPNSYT1mQIjDt4VXhpuusK9q5N_min.jpg',
              original: null,
              order: 1,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 1,
        views: 54880,
        comments: 1,
        number: null,
        comment: null,
      },
    ],
    number: null,
    comment: null,
    seo: {
      model_type: 'metadata',
      metatitle: 'Политические итоги Великой Отечественной войны',
      metadescription:
        'Ближайшим и наиболее очевидным итогом Второй мировой войны были гигантские разрушения и людские потери. Война опустошила целые страны, обратила в развалины города и села, привела к гибели многих миллионов людей. \nОднако итоги Второй мировой войны не сводятся только к потерям и разрушениям. В результате войны изменился облик мира. \nГлавный политический итог Второй мировой войны заключается в победе над фашистскими агрессорами. Страны и народы, которым угрожал фашизм, отстояли свою независимость и свободу. Агрессивные государства: Германия, Италия, Япония и их союзники — были разгромлены. Их вооруженные силы, экономика, политика, идеология потерпели полный крах; их главарей отдали под суд, и они понесли заслуженное наказание.\nПолностью дискредитировала себя идеология фашизма, нацизма, расизма, колониализма.',
      index: true,
      follow: true,
      archive: true,
      last_modified: '2024-12-04T08:09:42.000000Z',
    },
    content_types: [
      {
        model: 'article',
        value: ['article'],
        name: 'Статьи',
        count: 11,
      },
      {
        model: 'audiomaterial',
        value: ['audiolecture'],
        name: 'Аудио',
        count: 0,
      },
      {
        model: 'videomaterial',
        value: ['videolecture', 'film'],
        name: 'Видео',
        count: 2,
      },
      {
        model: 'test',
        value: ['test'],
        name: 'Тесты',
        count: 0,
      },
      {
        model: 'biography',
        value: ['biography'],
        name: 'Биографии',
        count: 0,
      },
      {
        model: 'wiki',
        value: ['wiki'],
        name: 'Энциклопедии',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'region_heraldic',
        value: ['region_heraldic'],
        name: 'Регионы | Символика',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_route',
        value: ['region_route'],
        name: 'Регионы | Маршруты',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_article',
        value: ['region_article'],
        name: 'Регионы | Статьи',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'illustration',
        value: ['illustration'],
        name: 'Карты и иллюстрации',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document_collection',
        value: ['document_collection'],
        name: 'Документы',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document',
        value: ['document'],
        name: 'Документы',
        count: 0,
      },
    ],
  },
  {
    model_type: 'highlight',
    id: 101,
    title: 'Освобождение Европы',
    preamble: null,
    slug: 'osvobozhdenie-narodov-evropy-pobeda-sssr-v-velikoy-otechestvennoy-voyne',
    announce:
      'Освобождение  народов Европы.  Победа СССР в Великой  Отечественной  войне.',
    show_in_main: false,
    published_at: '2023-10-16T20:11:52.000000Z',
    count: 18,
    likes: 0,
    views: 927,
    has_like: false,
    has_bookmark: false,
    image: {
      model_type: 'image',
      id: 26016,
      alt: null,
      src: '/images/highlight/27/nS3IgJZaDst4NVUz6CIjqP15AfIex9viTxcczX3l.jpg',
      preview:
        '/images/highlight/27/nS3IgJZaDst4NVUz6CIjqP15AfIex9viTxcczX3l_min.jpg',
      original: null,
      order: 1,
      desktop:
        '/images/highlight/27/yPjcHVAc8kJbakaPLdVHzScKyFa642jWq2x6l9Xm1697494799.jpg',
      mobile:
        '/images/highlight/27/5c4BdfXAnwrDJR29I8YgFOETNldsNAg2FM7mCiHg1697494799.jpg',
      app: '/images/highlight/27/3JsOa6nDNaOKap7zsVDodB4JsDt3mNgAHRSj1iNj1697494799.jpg',
    },
    list: [
      {
        model_type: 'lecture',
        id: 997,
        title: 'С. Горшков.  Советская кавалерия в  Венгрии.',
        preamble: null,
        slug: 's-gorshkov-sovetskaya-kavaleriya-v-vengrii',
        image: {
          model_type: 'image',
          id: 25997,
          alt: null,
          src: '/images/videomaterial/26/zQNclDBvGtTpD4NUg6rZr9tPXUEAgGDqasyBB5PR.jpg',
          preview:
            '/images/videomaterial/26/zQNclDBvGtTpD4NUg6rZr9tPXUEAgGDqasyBB5PR_min.jpg',
          original: null,
          order: 1,
          desktop:
            '/images/videomaterial/26/9PPYeNEI6LckkIsdQO8495CBbWlUagVbGX9lZzPf1697492828.jpg',
          mobile:
            '/images/videomaterial/26/HR7jtKAOrXs8O7A7i65XqJuEOFPwcclZ5xdafq4h1697492828.jpg',
          app: '/images/videomaterial/26/6gVFuSwqyRDgn1J3tJnVrVP6AaaLXJJzlyXzgmVK1697492828.jpg',
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2023-10-16T20:45:12.000000Z',
        authors: null,
        likes: 0,
        views: 704,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'lecture',
        id: 998,
        title: 'И. Кириченко.  Висло-Одерская  операция.',
        preamble: null,
        slug: 'i-kirichenko-vislo-oderskaya-operaciya',
        image: {
          model_type: 'image',
          id: 25998,
          alt: null,
          src: '/images/videomaterial/26/qLO9LqmecYyov6bPQzxM4QnsddmiFeOscVfeGVHv.jpg',
          preview:
            '/images/videomaterial/26/qLO9LqmecYyov6bPQzxM4QnsddmiFeOscVfeGVHv_min.jpg',
          original: null,
          order: 1,
          desktop:
            '/images/videomaterial/26/a1lt9Lcz5Cqk1GgDg48jzZhyCvsrago0hFuO9ky21697492917.jpg',
          mobile:
            '/images/videomaterial/26/QkbpqZpxVwoajmmyqOj0qiulvcZgcks4dcgIuyBy1697492917.jpg',
          app: '/images/videomaterial/26/SLlYn0NKFICXn1S5nUvUiWIJt86RgJ6hnQ2cbqxr1697492917.jpg',
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2023-10-16T20:47:15.000000Z',
        authors: null,
        likes: 0,
        views: 680,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'lecture',
        id: 999,
        title: 'Г. Копейкин. Штурм  фортов Познани.',
        preamble: null,
        slug: 'g-kopeykin-shturm-fortov-poznani',
        image: {
          model_type: 'image',
          id: 25999,
          alt: null,
          src: '/images/videomaterial/26/kb9PAo2VpQTXEEg8ivbjgpGovQ2ZlRX0eB36ZfQh.jpg',
          preview:
            '/images/videomaterial/26/kb9PAo2VpQTXEEg8ivbjgpGovQ2ZlRX0eB36ZfQh_min.jpg',
          original: null,
          order: 1,
          desktop:
            '/images/videomaterial/26/d1C1OR7ANeEX0gZsFpw2Q9RvIhzP2J1LoFuj9Mnh1697493014.jpg',
          mobile:
            '/images/videomaterial/26/UE2tLGakFq6SJ3Tx5KMlvrrw2mc41ZSlCACC2ida1697493014.jpg',
          app: '/images/videomaterial/26/xO4FEzyi3tD9a5vT4wLPxb8jxHtHbW2e3LyK9Grp1697493014.jpg',
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2023-10-16T20:48:43.000000Z',
        authors: null,
        likes: 0,
        views: 585,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'lecture',
        id: 1001,
        title: 'Великая  Отечественная война.  Освобождение.',
        preamble: null,
        slug: 'velikaya-otechestvennaya-voyna-osvobozhdenie',
        image: {
          model_type: 'image',
          id: 26001,
          alt: null,
          src: '/images/videomaterial/27/ll7UhV41dLAUvTcOcja68L0ulOXsutyYzeIUrd1G.jpg',
          preview:
            '/images/videomaterial/27/ll7UhV41dLAUvTcOcja68L0ulOXsutyYzeIUrd1G_min.jpg',
          original: null,
          order: 1,
          desktop:
            '/images/videomaterial/27/iod8aljJpE9DevWDpKlfYIbJxhhQplkVB0ROXxPN1697493193.jpg',
          mobile:
            '/images/videomaterial/27/58yfowoKXYAdloPe3f6554TmdRS0w0hapZUSPZrY1697493193.jpg',
          app: '/images/videomaterial/27/26nhDgoF5kXY26ifymT5pSYkAPJGdcD4EXIFrZvP1697493193.jpg',
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2023-10-16T20:51:43.000000Z',
        authors: null,
        likes: 0,
        views: 596,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'lecture',
        id: 1002,
        title:
          'Чего не должна  забывать Европа:  освобождение Красной  Армией нацистских  концлагерей в 1944- 1945 гг.',
        preamble: null,
        slug: 'chego-ne-dolzhna-zabyvat-evropa-osvobozhdenie-krasnoy-armiey-nacistskih-konclagerey-v-1944-1945-gg',
        image: {
          model_type: 'image',
          id: 26002,
          alt: null,
          src: '/images/videomaterial/27/RNDP8n1vCbN9bWF82YoDoZ6mymDQYFMben5adZh1.jpg',
          preview:
            '/images/videomaterial/27/RNDP8n1vCbN9bWF82YoDoZ6mymDQYFMben5adZh1_min.jpg',
          original: null,
          order: 1,
          desktop:
            '/images/videomaterial/27/zrT3oo4CArb0SUwMDVkcGZQBLGWcdRGi0JjL2kZl1697493330.jpg',
          mobile:
            '/images/videomaterial/27/9BvHGTKApvKtWrL3XSAWKvY5VwurrVb3Jo2sQeK61697493330.jpg',
          app: '/images/videomaterial/27/UeU2jLZWyusVbJuNEVx4WBwUnIdLnamJbLslv5iJ1697493330.jpg',
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2023-10-16T20:53:57.000000Z',
        authors: null,
        likes: 0,
        views: 602,
        comments: 0,
        number: null,
        comment: null,
      },
    ],
    number: null,
    comment: null,
    seo: {
      model_type: 'metadata',
      metatitle:
        'Освобождение  народов Европы.  Победа СССР в Великой  Отечественной  войне.',
      metadescription:
        'Освобождение  народов Европы.  Победа СССР в Великой  Отечественной  войне.',
      index: true,
      follow: true,
      archive: true,
      last_modified: '2024-11-27T11:28:01.000000Z',
    },
    content_types: [
      {
        model: 'article',
        value: ['article'],
        name: 'Статьи',
        count: 0,
      },
      {
        model: 'audiomaterial',
        value: ['audiolecture'],
        name: 'Аудио',
        count: 0,
      },
      {
        model: 'videomaterial',
        value: ['videolecture', 'film'],
        name: 'Видео',
        count: 18,
      },
      {
        model: 'test',
        value: ['test'],
        name: 'Тесты',
        count: 0,
      },
      {
        model: 'biography',
        value: ['biography'],
        name: 'Биографии',
        count: 0,
      },
      {
        model: 'wiki',
        value: ['wiki'],
        name: 'Энциклопедии',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'region_heraldic',
        value: ['region_heraldic'],
        name: 'Регионы | Символика',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_route',
        value: ['region_route'],
        name: 'Регионы | Маршруты',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_article',
        value: ['region_article'],
        name: 'Регионы | Статьи',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'illustration',
        value: ['illustration'],
        name: 'Карты и иллюстрации',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document_collection',
        value: ['document_collection'],
        name: 'Документы',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document',
        value: ['document'],
        name: 'Документы',
        count: 0,
      },
    ],
  },
  {
    model_type: 'highlight',
    id: 160,
    title: 'Броня Победы',
    preamble: null,
    slug: 'tanki-pobedy',
    announce:
      'В годы Великой Отечественной войны эти боевые машины позволили советским танкистам драться на равных с немецкими "тиграми", "пантерами" и "фердинандами". Грозное оружие Великой Отечественной — в нашей подборке.',
    show_in_main: false,
    published_at: '2023-11-21T06:19:54.000000Z',
    count: 14,
    likes: 1,
    views: 1624,
    has_like: false,
    has_bookmark: false,
    image: {
      model_type: 'image',
      id: 27109,
      alt: null,
      src: '/images/highlight/28/a9yup2CECjmQaIGXrQW4il9uvH5witYLoIAvJjGq.jpg',
      preview:
        '/images/highlight/28/a9yup2CECjmQaIGXrQW4il9uvH5witYLoIAvJjGq_min.jpg',
      original: null,
      order: 1,
      desktop:
        '/images/highlight/28/AbbLOmMC0mBzbEv2BZhFhqP4grimqoFIq7N5xWeZ1700578650.jpg',
      mobile:
        '/images/highlight/28/bd4nmqoeCIymXFauEGUok5LsEk9HEVcehqDSpt741700578650.jpg',
      app: '/images/highlight/28/8FTgfyFPJI76ZVV6lIlsKcXZ0ZpfyoXnoBmHh9Sx1700578650.jpg',
    },
    list: [
      {
        model_type: 'article',
        id: 3731,
        title: 'Броня Победы: история танка Т-34',
        preamble: null,
        slug: 'bronia-pobiedy-istoriia-tanka-t-34',
        image: {
          model_type: 'image',
          id: 1073,
          alt: null,
          src: '/images/articles/02/2HNIudmfrR5CZMF1nJQsHjRCAv5jHo6TeGYBiEF5.jpg',
          preview:
            '/images/articles/02/2HNIudmfrR5CZMF1nJQsHjRCAv5jHo6TeGYBiEF5_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2020-03-31T19:17:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 624,
            slug: 'buranov-nikita',
            firstname: 'Никита',
            surname: 'Буранов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: 11070,
              alt: null,
              src: '/images/author/12/EKuq66iGh0i93TE0wHfhXd8mgMPFwepnQ56am7QT.jpg',
              preview:
                '/images/author/12/EKuq66iGh0i93TE0wHfhXd8mgMPFwepnQ56am7QT_min.jpg',
              original: null,
              order: 1,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 32,
        views: 117080,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 4824,
        title: 'Танк Т-60. Малый, да удалый',
        preamble: null,
        slug: 'tank-t-60-malyy-da-udalyy',
        image: {
          model_type: 'image',
          id: 10909,
          alt: null,
          src: '/images/articles/11/iqIWbPseRaWTCHSSwXsGwNCf5GU9xg1N9v0GQHJX.jpg',
          preview:
            '/images/articles/11/iqIWbPseRaWTCHSSwXsGwNCf5GU9xg1N9v0GQHJX_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2021-10-04T11:00:36.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 657,
            slug: 'trofimov-anton',
            firstname: 'Антон',
            surname: 'Трофимов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 4,
        views: 30067,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 4959,
        title: 'Танк ИС-3. Тяжелые бронекулаки Советского Союза',
        preamble: null,
        slug: 'tyazhelyy-tank-is-3-tyazhelye-bronekulaki-sovetskogo-soyuza',
        image: {
          model_type: 'image',
          id: 13462,
          alt: null,
          src: '/images/articles/14/EGWhiVLjgfJstdpaLpFk2eKJunXFpdjUcY5ta4Iw.jpg',
          preview:
            '/images/articles/14/EGWhiVLjgfJstdpaLpFk2eKJunXFpdjUcY5ta4Iw_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2021-12-07T04:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 657,
            slug: 'trofimov-anton',
            firstname: 'Антон',
            surname: 'Трофимов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 13,
        views: 28414,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 5015,
        title: 'Плавающий танк ПТ-76. Наследник Т-40 и предшественник БМП-1',
        preamble: null,
        slug: 'plavayushchiy-tank-pt-76-naslednik-t-40-i-predshestvennik-bmp-1',
        image: {
          model_type: 'image',
          id: 14110,
          alt: null,
          src: '/images/articles/15/Kb91XcnB2dQYYsVDLrrR9tT6MM1tAfRJNGGYKoDI.jpg',
          preview:
            '/images/articles/15/Kb91XcnB2dQYYsVDLrrR9tT6MM1tAfRJNGGYKoDI_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2022-06-05T11:36:05.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 657,
            slug: 'trofimov-anton',
            firstname: 'Антон',
            surname: 'Трофимов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 4,
        views: 35381,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 6347,
        title:
          'ОТ-34 – самый массовый огнеметный танк Великой Отечественной войны',
        preamble: null,
        slug: 'ot-34-samyy-massovyy-ognemetnyy-tank-velikoy-otechestvennoy-voyny',
        image: {
          model_type: 'image',
          id: 15711,
          alt: null,
          src: '/images/articles/16/tBnD2efmIJKw4jSJbhwUddpcbNvZbXPkHXkrH6tb.jpg',
          preview:
            '/images/articles/16/tBnD2efmIJKw4jSJbhwUddpcbNvZbXPkHXkrH6tb_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2022-07-19T08:50:21.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 752,
            slug: 'starshov-yuriy-2',
            firstname: 'Юрий',
            surname: 'Старшов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: 15416,
              alt: null,
              src: '/images/author/16/nTHOX7kBbp9UqhbsxcIGd5XTiM3u1ihpDuyiV9S5.jpg',
              preview:
                '/images/author/16/nTHOX7kBbp9UqhbsxcIGd5XTiM3u1ihpDuyiV9S5_min.jpg',
              original: null,
              order: 1,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: null,
          },
        ],
        likes: 4,
        views: 19744,
        comments: 0,
        number: null,
        comment: null,
      },
    ],
    number: null,
    comment: null,
    seo: {
      model_type: 'metadata',
      metatitle: 'Броня Победы',
      metadescription:
        'В годы Великой Отечественной войны эти боевые машины позволили советским танкистам драться на равных с немецкими "тиграми", "пантерами" и "фердинандами". Грозное оружие Великой Отечественной — в нашей подборке.',
      index: true,
      follow: true,
      archive: true,
      last_modified: '2024-12-04T08:16:05.000000Z',
    },
    content_types: [
      {
        model: 'article',
        value: ['article'],
        name: 'Статьи',
        count: 13,
      },
      {
        model: 'audiomaterial',
        value: ['audiolecture'],
        name: 'Аудио',
        count: 0,
      },
      {
        model: 'videomaterial',
        value: ['videolecture', 'film'],
        name: 'Видео',
        count: 0,
      },
      {
        model: 'test',
        value: ['test'],
        name: 'Тесты',
        count: 0,
      },
      {
        model: 'biography',
        value: ['biography'],
        name: 'Биографии',
        count: 0,
      },
      {
        model: 'wiki',
        value: ['wiki'],
        name: 'Энциклопедии',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'region_heraldic',
        value: ['region_heraldic'],
        name: 'Регионы | Символика',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_route',
        value: ['region_route'],
        name: 'Регионы | Маршруты',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_article',
        value: ['region_article'],
        name: 'Регионы | Статьи',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'illustration',
        value: ['illustration'],
        name: 'Карты и иллюстрации',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document_collection',
        value: ['document_collection'],
        name: 'Документы',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document',
        value: ['document'],
        name: 'Документы',
        count: 0,
      },
    ],
  },
  // {
  //   model_type: 'highlight',
  //   id: 901,
  //   title: 'Дальневосточный фронт',
  //   preamble: null,
  //   slug: 'dalnevostochnyy-front',
  //   announce:
  //     'Материалы, рассказывающие о ключевых событиях на Дальневосточном фронте Великой Отечественной войны',
  //   show_in_main: false,
  //   published_at: '2024-11-27T08:32:34.000000Z',
  //   count: 5,
  //   likes: 0,
  //   views: 4,
  //   has_like: false,
  //   has_bookmark: false,
  //   image: {
  //     model_type: 'image',
  //     id: 43106,
  //     alt: null,
  //     src: '/images/highlight/44/RpIp5y1HvbozXCL30EQPOYEGI9Kthda90t5gaen0.jpg',
  //     preview:
  //       '/images/highlight/44/RpIp5y1HvbozXCL30EQPOYEGI9Kthda90t5gaen0_min.jpg',
  //     original: null,
  //     order: 1,
  //     desktop:
  //       '/images/highlight/44/6Hbi2Ku9VVINAgAKudABJLZdBrKthjNvFkyxC3nV1732700781.jpg',
  //     mobile:
  //       '/images/highlight/44/zL9Nwy9ZYxOCsQEuWkzEDSTQxwVDxE714MzCBIZ71732700781.jpg',
  //     app: '/images/highlight/44/k0osDfXGrymvdoSvGyFckUWKfgwvIAQDNJ6yOY9S1732700781.jpg',
  //   },
  //   list: [
  //     {
  //       model_type: 'article',
  //       id: 4299,
  //       title: 'Маньчжурская стратегическая наступательная операция',
  //       preamble: null,
  //       slug: 'man-chzhurskaia-stratieghichieskaia-nastupatiel-naia-opieratsiia-event',
  //       image: {
  //         model_type: 'image',
  //         id: 20453,
  //         alt: null,
  //         src: '/images/articles/21/v2K8DRk1kzwTHhBga0H7Bd8Q1XpKwTvBqCdMwVxg.jpg',
  //         preview:
  //           '/images/articles/21/v2K8DRk1kzwTHhBga0H7Bd8Q1XpKwTvBqCdMwVxg_min.jpg',
  //         original: null,
  //         order: 1,
  //         desktop: null,
  //         mobile: null,
  //         app: null,
  //       },
  //       max_points: null,
  //       questions: null,
  //       is_reversable: null,
  //       published_at: '2013-06-25T13:27:45.000000Z',
  //       authors: [],
  //       likes: 10,
  //       views: 33131,
  //       comments: 0,
  //       number: null,
  //       comment: null,
  //     },
  //     {
  //       model_type: 'article',
  //       id: 3774,
  //       title: 'Маньчжурия в годы Великой Отечественной войны',
  //       preamble: null,
  //       slug: 'manchzhuriia-v-ghody-vielikoi-otiechiestviennoi-voiny',
  //       image: {
  //         model_type: 'image',
  //         id: 1120,
  //         alt: null,
  //         src: '/images/articles/02/Acxth4cwRr8ArV10v7txILCblgOdMBJnLRsAkKwD.jpg',
  //         preview:
  //           '/images/articles/02/Acxth4cwRr8ArV10v7txILCblgOdMBJnLRsAkKwD_min.jpg',
  //         original: null,
  //         order: 1,
  //         desktop: null,
  //         mobile: null,
  //         app: null,
  //       },
  //       max_points: null,
  //       questions: null,
  //       is_reversable: null,
  //       published_at: '2020-05-08T13:37:00.000000Z',
  //       authors: [],
  //       likes: 0,
  //       views: 5682,
  //       comments: 0,
  //       number: null,
  //       comment: null,
  //     },
  //     {
  //       model_type: 'article',
  //       id: 3769,
  //       title: 'Освобождение Корейского полуострова',
  //       preamble: null,
  //       slug: 'osvobozhdieniie-korieiskogho-poluostrova',
  //       image: {
  //         model_type: 'image',
  //         id: 1113,
  //         alt: null,
  //         src: '/images/articles/02/GEehn8EioPOu4KC2nb8JHd3oy3GlI93nagIqhnOV.jpg',
  //         preview:
  //           '/images/articles/02/GEehn8EioPOu4KC2nb8JHd3oy3GlI93nagIqhnOV_min.jpg',
  //         original: null,
  //         order: 1,
  //         desktop: null,
  //         mobile: null,
  //         app: null,
  //       },
  //       max_points: null,
  //       questions: null,
  //       is_reversable: null,
  //       published_at: '2020-05-08T12:58:00.000000Z',
  //       authors: [],
  //       likes: 0,
  //       views: 2957,
  //       comments: 0,
  //       number: null,
  //       comment: null,
  //     },
  //     {
  //       model_type: 'article',
  //       id: 3290,
  //       title: '«Августовская буря» над Маньчжурией',
  //       preamble: null,
  //       slug: 'avghustovskaia-buria-nad-manchzhuriiei',
  //       image: {
  //         model_type: 'image',
  //         id: 4270,
  //         alt: null,
  //         src: '/images/articles/05/nleDBnz9ybgZ6p9iU95Prlai1z5FxQXXFTzMXss2.jpg',
  //         preview:
  //           '/images/articles/05/nleDBnz9ybgZ6p9iU95Prlai1z5FxQXXFTzMXss2_min.jpg',
  //         original: null,
  //         order: 1,
  //         desktop: null,
  //         mobile: null,
  //         app: null,
  //       },
  //       max_points: null,
  //       questions: null,
  //       is_reversable: null,
  //       published_at: '2019-08-09T01:00:00.000000Z',
  //       authors: [
  //         {
  //           model_type: 'author',
  //           id: 658,
  //           slug: 'antonov-sierghiei',
  //           firstname: 'Сергей',
  //           surname: 'Антонов',
  //           patronymic: '',
  //           image: {
  //             model_type: 'image',
  //             id: null,
  //             alt: null,
  //             src: '/images/user/blank-avatar-new.png',
  //             preview: '/images/user/blank-avatar-new_min.png',
  //             original: null,
  //             order: null,
  //             desktop: null,
  //             mobile: null,
  //             app: null,
  //           },
  //           announce: null,
  //           is_subscribed: false,
  //         },
  //       ],
  //       likes: 4,
  //       views: 22971,
  //       comments: 1,
  //       number: null,
  //       comment: null,
  //     },
  //     {
  //       model_type: 'article',
  //       id: 980,
  //       title:
  //         'Уроки недопрославленной победы. К годовщине разгрома Японии и окончания Второй мировой войны',
  //       preamble: null,
  //       slug: 'uroki-niedoproslavliennoi-pobiedy-k-71-i-ghodovshchinie-razghroma-iaponii-i-okonchaniia-vtoroi-mirovoi-voiny',
  //       image: {
  //         model_type: 'image',
  //         id: 3929,
  //         alt: null,
  //         src: '/images/articles/04/Nm0wyR2rf4dARl12WgtBs3Csz53xZQf1Fmm65Mlv.jpg',
  //         preview:
  //           '/images/articles/04/Nm0wyR2rf4dARl12WgtBs3Csz53xZQf1Fmm65Mlv_min.jpg',
  //         original: null,
  //         order: 1,
  //         desktop: null,
  //         mobile: null,
  //         app: null,
  //       },
  //       max_points: null,
  //       questions: null,
  //       is_reversable: null,
  //       published_at: '2016-09-05T00:00:00.000000Z',
  //       authors: [],
  //       likes: 1,
  //       views: 4126,
  //       comments: 0,
  //       number: null,
  //       comment: null,
  //     },
  //   ],
  //   number: null,
  //   comment: null,
  //   seo: {
  //     model_type: 'metadata',
  //     metatitle: 'Дальневосточный фронт',
  //     metadescription:
  //       'Материалы, рассказывающие о ключевых событиях на Дальневосточном фронте Великой Отечественной войны',
  //     index: true,
  //     follow: true,
  //     archive: true,
  //     last_modified: '2024-11-27T11:32:22.000000Z',
  //   },
  //   content_types: [
  //     {
  //       model: 'article',
  //       value: ['article'],
  //       name: 'Статьи',
  //       count: 5,
  //     },
  //     {
  //       model: 'audiomaterial',
  //       value: ['audiolecture'],
  //       name: 'Аудио',
  //       count: 0,
  //     },
  //     {
  //       model: 'videomaterial',
  //       value: ['videolecture', 'film'],
  //       name: 'Видео',
  //       count: 0,
  //     },
  //     {
  //       model: 'test',
  //       value: ['test'],
  //       name: 'Тесты',
  //       count: 0,
  //     },
  //     {
  //       model: 'biography',
  //       value: ['biography'],
  //       name: 'Биографии',
  //       count: 0,
  //     },
  //     {
  //       model: 'wiki',
  //       value: ['wiki'],
  //       name: 'Энциклопедии',
  //       count: 0,
  //       is_teacher: true,
  //     },
  //     {
  //       model: 'region_heraldic',
  //       value: ['region_heraldic'],
  //       name: 'Регионы | Символика',
  //       count: 0,
  //       is_teacher: false,
  //     },
  //     {
  //       model: 'region_route',
  //       value: ['region_route'],
  //       name: 'Регионы | Маршруты',
  //       count: 0,
  //       is_teacher: false,
  //     },
  //     {
  //       model: 'region_article',
  //       value: ['region_article'],
  //       name: 'Регионы | Статьи',
  //       count: 0,
  //       is_teacher: false,
  //     },
  //     {
  //       model: 'illustration',
  //       value: ['illustration'],
  //       name: 'Карты и иллюстрации',
  //       count: 0,
  //       is_teacher: true,
  //     },
  //     {
  //       model: 'document_collection',
  //       value: ['document_collection'],
  //       name: 'Документы',
  //       count: 0,
  //       is_teacher: true,
  //     },
  //     {
  //       model: 'document',
  //       value: ['document'],
  //       name: 'Документы',
  //       count: 0,
  //     },
  //   ],
  // },
  // {
  //   model_type: 'highlight',
  //   id: 73,
  //   title: 'Нюрнберг',
  //   preamble: null,
  //   slug: 'nyurnberg',
  //   announce:
  //     'Нюрнбергский трибунал  - самый громкий судебный процесс XX столетия который начался  20 ноября 1945г.  На скамье подсудимых оказалась вся правящая верхушка Третьего рейха. Уже 16 октября 1946 года в Нюрнбергской тюрьме был приведен в исполнение смертный приговор над  нацистским преступникам. О том, как была поставлена фактическая точка в истории Второй Мировой войны - подробнее в нашей подборке.',
  //   show_in_main: false,
  //   published_at: '2022-12-22T18:19:03.000000Z',
  //   count: 6,
  //   likes: 0,
  //   views: 3107,
  //   has_like: false,
  //   has_bookmark: false,
  //   image: {
  //     model_type: 'image',
  //     id: 18525,
  //     alt: null,
  //     src: '/images/highlight/19/9qEunuWLaLAXbHzouKBTiJm2aOznBdlgHzTkfgKo.jpg',
  //     preview:
  //       '/images/highlight/19/9qEunuWLaLAXbHzouKBTiJm2aOznBdlgHzTkfgKo_min.jpg',
  //     original: null,
  //     order: 1,
  //     desktop: null,
  //     mobile: null,
  //     app: null,
  //   },
  //   list: [
  //     {
  //       model_type: 'article',
  //       id: 4289,
  //       title:
  //         '20 ноября 1945 года в Нюрнберге начался процесс над руководством нацистской Германии',
  //       preamble: null,
  //       slug: 'v-niurnbierghie-nachalsia-protsiess-nad-rukovodstvom-natsistskoi-giermanii-event',
  //       image: {
  //         model_type: 'image',
  //         id: 20194,
  //         alt: null,
  //         src: '/images/articles/21/ZHTaWtAELL71imNClcGa5JbVV4T2N40oe8zfYjD8.jpg',
  //         preview:
  //           '/images/articles/21/ZHTaWtAELL71imNClcGa5JbVV4T2N40oe8zfYjD8_min.jpg',
  //         original: null,
  //         order: 1,
  //         desktop: null,
  //         mobile: null,
  //         app: null,
  //       },
  //       max_points: null,
  //       questions: null,
  //       is_reversable: null,
  //       published_at: '2013-06-25T13:27:45.000000Z',
  //       authors: [],
  //       likes: 0,
  //       views: 18060,
  //       comments: 0,
  //       number: null,
  //       comment: null,
  //     },
  //     {
  //       model_type: 'article',
  //       id: 4861,
  //       title: 'Турции не было ни в Берлине, ни в Нюрнберге',
  //       preamble: null,
  //       slug: 'turcii-ne-bylo-ni-v-berline-ni-v-nyurnberge',
  //       image: {
  //         model_type: 'image',
  //         id: 11740,
  //         alt: null,
  //         src: '/images/articles/12/rzK70lzGc6ywWGoXAS7gA6CoNgvqJ37dIc1ruhvB.jpg',
  //         preview:
  //           '/images/articles/12/rzK70lzGc6ywWGoXAS7gA6CoNgvqJ37dIc1ruhvB_min.jpg',
  //         original: null,
  //         order: 1,
  //         desktop: null,
  //         mobile: null,
  //         app: null,
  //       },
  //       max_points: null,
  //       questions: null,
  //       is_reversable: null,
  //       published_at: '2021-10-19T12:19:51.000000Z',
  //       authors: [
  //         {
  //           model_type: 'author',
  //           id: 431,
  //           slug: 'gasparian-armien',
  //           firstname: 'Армен',
  //           surname: 'Гаспарян',
  //           patronymic: '',
  //           image: {
  //             model_type: 'image',
  //             id: null,
  //             alt: null,
  //             src: '/images/user/blank-avatar-new.png',
  //             preview: '/images/user/blank-avatar-new_min.png',
  //             original: null,
  //             order: null,
  //             desktop: null,
  //             mobile: null,
  //             app: null,
  //           },
  //           announce: null,
  //           is_subscribed: false,
  //         },
  //       ],
  //       likes: 6,
  //       views: 26148,
  //       comments: 0,
  //       number: null,
  //       comment: null,
  //     },
  //     {
  //       model_type: 'article',
  //       id: 3901,
  //       title:
  //         'О политике нацистов в отношении мирного населения СССР. Выступление Владимира Мединского на форуме «Уроки Нюрнберга»',
  //       preamble: null,
  //       slug: 'o-politikie-natsistov-v-otnoshienii-mirnogho-nasielieniia-sssr-vystuplieniie-vladimira-miedinskogho-na-forumie-uroki-niurnbiergha',
  //       image: {
  //         model_type: 'image',
  //         id: 1255,
  //         alt: null,
  //         src: '/images/articles/02/x3oe8CiVnfp8giVbPMLVMcB3lgXxLtb3V9Eb5rdI.jpg',
  //         preview:
  //           '/images/articles/02/x3oe8CiVnfp8giVbPMLVMcB3lgXxLtb3V9Eb5rdI_min.jpg',
  //         original: null,
  //         order: 1,
  //         desktop: null,
  //         mobile: null,
  //         app: null,
  //       },
  //       max_points: null,
  //       questions: null,
  //       is_reversable: null,
  //       published_at: '2020-11-20T16:51:00.000000Z',
  //       authors: [],
  //       likes: 1,
  //       views: 3641,
  //       comments: 0,
  //       number: null,
  //       comment: null,
  //     },
  //     {
  //       model_type: 'article',
  //       id: 3402,
  //       title: 'Нюрнбергский процесс. Главный суд ХХ века в фактах и цифрах',
  //       preamble: null,
  //       slug: 'niurnbierghskii-protsiess-glavnyi-sud-khkh-vieka-v-faktakh-i-tsifrakh-1',
  //       image: {
  //         model_type: 'image',
  //         id: 699,
  //         alt: null,
  //         src: '/images/articles/01/LgvyJO4OIxpbpRRFroBNsPUBqbuPDNBdNlsqv59M.jpg',
  //         preview:
  //           '/images/articles/01/LgvyJO4OIxpbpRRFroBNsPUBqbuPDNBdNlsqv59M_min.jpg',
  //         original: null,
  //         order: 1,
  //         desktop: null,
  //         mobile: null,
  //         app: null,
  //       },
  //       max_points: null,
  //       questions: null,
  //       is_reversable: null,
  //       published_at: '2020-11-20T06:30:00.000000Z',
  //       authors: [
  //         {
  //           model_type: 'author',
  //           id: 657,
  //           slug: 'trofimov-anton',
  //           firstname: 'Антон',
  //           surname: 'Трофимов',
  //           patronymic: '',
  //           image: {
  //             model_type: 'image',
  //             id: null,
  //             alt: null,
  //             src: '/images/user/blank-avatar-new.png',
  //             preview: '/images/user/blank-avatar-new_min.png',
  //             original: null,
  //             order: null,
  //             desktop: null,
  //             mobile: null,
  //             app: null,
  //           },
  //           announce: null,
  //           is_subscribed: false,
  //         },
  //       ],
  //       likes: 19,
  //       views: 201270,
  //       comments: 1,
  //       number: null,
  //       comment: null,
  //     },
  //     {
  //       model_type: 'article',
  //       id: 2086,
  //       title: 'Краткий курс истории. Японский «Нюрнберг»',
  //       preamble: null,
  //       slug: 'kratkii-kurs-istorii-iaponskii-niurnbiergh',
  //       image: {
  //         model_type: 'image',
  //         id: 1874,
  //         alt: null,
  //         src: '/images/articles/02/TrkTRx7oedOG7bnkSl3kfIBANBTGNzzKroPa6ROI.jpg',
  //         preview:
  //           '/images/articles/02/TrkTRx7oedOG7bnkSl3kfIBANBTGNzzKroPa6ROI_min.jpg',
  //         original: null,
  //         order: 1,
  //         desktop: null,
  //         mobile: null,
  //         app: null,
  //       },
  //       max_points: null,
  //       questions: null,
  //       is_reversable: null,
  //       published_at: '2017-11-10T00:10:00.000000Z',
  //       authors: [
  //         {
  //           model_type: 'author',
  //           id: 504,
  //           slug: 'sukharnikov-ievghienii-sierghieievich',
  //           firstname: 'Евгений',
  //           surname: 'Сухарников',
  //           patronymic: '',
  //           image: {
  //             model_type: 'image',
  //             id: 11307,
  //             alt: null,
  //             src: '/images/author/12/EHSgrEtxioK4IEMoNidkiBEY2mcdhRjZFTnfXW6l.jpg',
  //             preview:
  //               '/images/author/12/EHSgrEtxioK4IEMoNidkiBEY2mcdhRjZFTnfXW6l_min.jpg',
  //             original: null,
  //             order: 1,
  //             desktop: null,
  //             mobile: null,
  //             app: null,
  //           },
  //           announce: null,
  //           is_subscribed: false,
  //         },
  //       ],
  //       likes: 4,
  //       views: 25020,
  //       comments: 0,
  //       number: null,
  //       comment: null,
  //     },
  //   ],
  //   number: null,
  //   comment: null,
  //   seo: {
  //     model_type: 'metadata',
  //     metatitle: 'Нюрнберг',
  //     metadescription:
  //       'Нюрнбергский трибунал  - самый громкий судебный процесс XX столетия который начался  20 ноября 1945г.  На скамье подсудимых оказалась вся правящая верхушка Третьего рейха. Уже 16 октября 1946 года в Нюрнбергской тюрьме был приведен в исполнение смертный приговор над  нацистским преступникам. О том, как была поставлена фактическая точка в истории Второй Мировой войны - подробнее в нашей подборке.',
  //     index: true,
  //     follow: true,
  //     archive: true,
  //     last_modified: '2024-11-27T11:25:53.000000Z',
  //   },
  //   content_types: [
  //     {
  //       model: 'article',
  //       value: ['article'],
  //       name: 'Статьи',
  //       count: 5,
  //     },
  //     {
  //       model: 'audiomaterial',
  //       value: ['audiolecture'],
  //       name: 'Аудио',
  //       count: 0,
  //     },
  //     {
  //       model: 'videomaterial',
  //       value: ['videolecture', 'film'],
  //       name: 'Видео',
  //       count: 0,
  //     },
  //     {
  //       model: 'test',
  //       value: ['test'],
  //       name: 'Тесты',
  //       count: 0,
  //     },
  //     {
  //       model: 'biography',
  //       value: ['biography'],
  //       name: 'Биографии',
  //       count: 0,
  //     },
  //     {
  //       model: 'wiki',
  //       value: ['wiki'],
  //       name: 'Энциклопедии',
  //       count: 0,
  //       is_teacher: true,
  //     },
  //     {
  //       model: 'region_heraldic',
  //       value: ['region_heraldic'],
  //       name: 'Регионы | Символика',
  //       count: 0,
  //       is_teacher: false,
  //     },
  //     {
  //       model: 'region_route',
  //       value: ['region_route'],
  //       name: 'Регионы | Маршруты',
  //       count: 0,
  //       is_teacher: false,
  //     },
  //     {
  //       model: 'region_article',
  //       value: ['region_article'],
  //       name: 'Регионы | Статьи',
  //       count: 0,
  //       is_teacher: false,
  //     },
  //     {
  //       model: 'illustration',
  //       value: ['illustration'],
  //       name: 'Карты и иллюстрации',
  //       count: 0,
  //       is_teacher: true,
  //     },
  //     {
  //       model: 'document_collection',
  //       value: ['document_collection'],
  //       name: 'Документы',
  //       count: 0,
  //       is_teacher: true,
  //     },
  //     {
  //       model: 'document',
  //       value: ['document'],
  //       name: 'Документы',
  //       count: 0,
  //     },
  //   ],
  // },
];
