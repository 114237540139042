<template>
  <section>
    <div class="flex w-full flex-col lg:flex-row">
      <div class="mb-5 w-full lg:mb-0 lg:mr-[2.09151%] lg:w-[74.64052%]">
        <MainPagePinnedElementsItem
          :data="data[0]"
          disable-author
          disable-label
          is-pagination
        />
      </div>
      <div
        class="flex w-full flex-col justify-between md:flex-row lg:w-[23.26797%] lg:flex-col"
      >
        <MainPagePinnedElementsItem
          v-for="(item, index) in getData"
          :key="index"
          :data="item"
          class="mb-5 w-full last:mr-0 md:mb-0 md:mr-5 md:w-[33.3333%] lg:mr-[initial] lg:w-[initial]"
          disable-title
          disable-announce
          disable-label
          is-blank
        />
      </div>
    </div>
  </section>
</template>

<script setup>
defineProps({
  data: {
    type: Array,
    default: () => [],
  },
});

const getData = computed(() => {
  return [
    [
      {
        model_type: 'specialproject',
        id: 1,
        title: '',
        slug: '/teacher',
        announce: '',
        image: {
          model_type: 'image',
          id: 3771,
          alt: null,
          src: `/imgs/banner_4.jpg`,
          preview: `/imgs/banner_4.jpg`,
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
      },
    ],
    [
      {
        model_type: 'specialproject',
        id: 2,
        title: '',
        slug: 'https://t.me/vr_medinskiy',
        announce: '',
        image: {
          model_type: 'image',
          id: 3771,
          alt: null,
          src: `/imgs/banner_2.jpeg`,
          preview: `/imgs/banner_2.jpeg`,
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
      },
    ],
    [
      {
        model_type: 'specialproject',
        id: 3,
        title: '',
        slug: 'https://histrf.ru/read/articles/rossiyskaya-istoricheskaya-enciklopediya',
        announce: '',
        image: {
          model_type: 'image',
          id: 3771,
          alt: null,
          src: `/imgs/banners/829d7234-81cd-433b-8b9a-da37d1ae07cf-min.jpg`,
          preview: `/imgs/banners/829d7234-81cd-433b-8b9a-da37d1ae07cf-min.jpg`,
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
      },
    ],
  ];
});

/**
 * backup banners
 * 
 * 
        [
          {
            model_type: 'specialproject',
            id: 1,
            title: '',
            slug: 'https://t.me/rf_history',
            announce: '',
            image: {
              model_type: 'image',
              id: 3771,
              alt: null,
              src: `/imgs/banner_1.jpg`,
              preview: `/imgs/banner_1.jpg`,
              original: null,
              order: 1,
              desktop: null,
              mobile: null,
              app: null,
            },
          },
        ],
 */
</script>
