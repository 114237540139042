<template>
  <div class="flex h-full flex-col">
    <div class="mb-[20px] flex items-center justify-between gap-[20px]">
      <h2
        class="font-roboto text-[32px] font-medium leading-[38px] text-new-test-black"
      >
        Тест по истории. Победный 45-й
      </h2>
    </div>

    <div class="grid-day grid h-full" :class="{ full: !isMobile }">
      <div class="yesterday-top">
        <MainPageCardsReworkedBaseCard
          class="h-full"
          :data="staticCollection[0]"
          disable-announce
          disable-label
        />
      </div>
      <div class="yesterday-bottom">
        <MainPageCardsReworkedBaseCard
          class="h-full"
          :data="staticCollection[1]"
          disable-announce
          disable-label
        />
      </div>
      <div class="today">
        <MainPageCardsReworkedBaseCard
          class="h-full"
          :data="staticCollection[2]"
          :priority-image="staticCollection[2].priorityImage"
          disable-announce
          disable-label
        />
      </div>
      <div class="tomorrow-top">
        <MainPageCardsReworkedBaseCard
          class=""
          :data="staticCollection[3]"
          disable-announce
          disable-label
        />
      </div>
      <div class="tomorrow-bottom">
        <MainPageCardsReworkedBaseCard
          class="col-span-full lg:col-span-2 lg:col-start-3 lg:row-start-2 xl:col-span-1 xl:col-start-4"
          :data="staticCollection[4]"
          disable-announce
          disable-label
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { staticCollection } from '~/assets/data/staticCollection';

const { isMobile } = useBreakpoint();
</script>

<style lang="scss" scoped>
.day-title {
  @apply text-[32px] leading-[38px] text-[#1f1f1f] font-roboto font-medium;
}
.y-title {
  grid-area: y-t;
}
.yesterday-top {
  grid-area: y-top;
}
.yesterday-bottom {
  grid-area: y-bottom;
}
.tod-title {
  grid-area: td-t;
}
.today {
  grid-area: td;
}
.tom-title {
  grid-area: tm-t;
}
.tomorrow-top {
  grid-area: tm-top;
}
.tomorrow-bottom {
  grid-area: tm-bottom;
}

.grid-day {
  $grid-day-gap: 20px;
  gap: $grid-day-gap;
  grid-template:
    'y-top td tm-top' 1fr
    'y-bottom td tm-bottom' 1fr / 1fr 2fr 1fr;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .grid-day {
    $grid-day-gap: 12px;
    gap: $grid-day-gap;
  }
  .grid-day.full {
    grid-template:
      'y-top y-top td td td td' 1fr
      'y-bottom y-bottom td td td td' 1fr
      'tm-top tm-top tm-top tm-bottom tm-bottom tm-bottom' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .grid-day.not-top-yesterday {
    grid-template:
      'y-bottom y-bottom y-bottom td td td' 0.5fr
      'y-bottom y-bottom y-bottom td td td' 0.5fr
      'tm-top tm-top tm-top tm-bottom tm-bottom tm-bottom' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .grid-day.not-bottom-yesterday {
    grid-template:
      'y-top y-top y-top td td td' 0.5fr
      'y-top y-top y-top td td td' 0.5fr
      'tm-top tm-top tm-top tm-bottom tm-bottom tm-bottom' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .grid-day.not-today {
    grid-template:
      'y-top y-top y-top y-bottom y-bottom y-bottom' 0.5fr
      'y-top y-top y-top y-bottom y-bottom y-bottom' 0.5fr
      'tm-top tm-top tm-top tm-bottom tm-bottom tm-bottom' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .grid-day.not-top-tomorrow {
    grid-template:
      'y-top y-top td td td td' 0.5fr
      'y-bottom y-bottom td td td td' 0.5fr
      'tm-bottom tm-bottom tm-bottom tm-bottom tm-bottom tm-bottom' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .grid-day.not-bottom-tomorrow {
    grid-template:
      'y-top y-top td td td td' 0.5fr
      'y-bottom y-bottom td td td td' 0.5fr
      'tm-top tm-top tm-top tm-top tm-top tm-top' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1024px) {
  .grid-day.full {
    grid-template:
      'y-top y-bottom' auto
      'td td' auto
      'tm-top tm-bottom' auto / 1fr 1fr;
  }
  .grid-day.not-top-yesterday {
    grid-template:
      'y-bottom y-bottom' auto
      'td td' auto
      'tm-top tm-bottom' auto / 1fr 1fr;
  }
  .grid-day.not-bottom-yesterday {
    grid-template:
      'y-top y-top' auto
      'td td' auto
      'tm-top tm-bottom' auto / 1fr 1fr;
  }
  .grid-day.not-top-tomorrow {
    grid-template:
      'y-top y-bottom' auto
      'td td' auto
      'tm-bottom tm-bottom' auto / 1fr 1fr;
  }
  .grid-day.not-bottom-tomorrow {
    grid-template:
      'y-top y-bottom' auto
      'td td' auto
      'tm-top tm-top' auto / 1fr 1fr;
  }
}
@media screen and (max-width: 768px) {
  .grid-day {
    grid-template:
      'y-top' auto
      'y-bottom' auto
      'td' auto
      'tm-top' auto
      'tm-bottom' auto / 1fr;
  }
}
</style>
