import api from '@/services/api/api';

export const useRandomAudioLectures = (per_page = 4, exclude) => {
  const randomAudioLectures = useQuery(
    ['randomAudioLectures'],
    async () => {
      const { data } = await api.lectures.getRandomAudioLecture(
        per_page,
        exclude,
      );
      return data;
    },
    {
      select: ({ data }) => data,
    },
  );

  onServerPrefetch(async () => {
    await randomAudioLectures.suspense();
  });

  return {
    randomAudioLectures,
  };
};
