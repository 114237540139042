import api from '@/services/api/api';

const defaultOptions = {
  staleTime: Infinity,
  refetchOnFocus: false,
  refetchOnReconnect: false,
};

export const useVideoMaterials = (params = { options: {}, per_page: 9 }) => {
  const videoMaterials = useQuery(
    ['videoMaterials'],
    async () => {
      const { data } = await api.lectures.getRandomVideoMaterials(
        params.per_page,
      );
      return data;
    },
    { ...defaultOptions, ...params.options, select: ({ data }) => data },
  );

  onServerPrefetch(async () => {
    await videoMaterials.suspense();
  });

  return {
    videoMaterials,
  };
};
